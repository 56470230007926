import React from "react"

import { footlink, footnote, /*footlink*/ } from "../shared/links"

import styles from "../../styles/main.module.css"

export default (props) =>  {
    const footnotes = [
        "I’ve wondered about whether companies or societies could be viewed as individual super-beings, but I’ve not probed too deeply into it. Hofstadter’s exploration of hypothetical levels of abstraction/meaning in the ant colony was elucidating primarily because its concreteness clarifies how such a super-being may work, or be understood.",
        "The section exploring tangled hierarchies was also interesting. That said, I sort of wish he’d tried to create a detailed example of a tangled hierarchical system (maybe defining a formal system). There were many examples of tangling existing, but not of their effects. The main argument Hofstadter pushed was that at the “bottom” there is some layer which is “static.” This is “obvious” insofar that the alternative is unthinkable, paradoxical, and wonky. However, it’s a cop-out. If our world is a tangled hierarchy, as it appears to be, it’s not particularly helpful to point to some yet-invisible “bottom” layer that is static. Perhaps we can show that certain types of infinite regresses are in fact equal to single items? How to work with a tangled hierarchy without looking for the “static” element would be perhaps more compelling for life (though, now that I think about it, by trying to create an “approach” to anything you are necessarily making static assumptions… hmm).",
        "If you are intimately familiar with formal systems, AI, and potentially cellular/molecular biology (and programming), you’ll find this read to feel a bit like a chore in various sections. I personally found it unexciting at times where subjects I already understood (programming, certain basic mathematical concepts) were explored. Where the subject-matter was new to me, or Hofstadter explored philosophical ideas, though, I think it was fascinating.",
        "Things feel very “Deep Learning or Bust” right now in the AI/ML world.",
        "This is on page 435, give or take.",
        "This is on page 215, give or take.",
        "Typographical number theory—a formalization of number theoretic mathematics. The idea is to be able to create a rigid set of rules and valid sentences to express truths and falsehoods, to try and “mechanize” or “automate” some forms of reasoning (primarily, deduction).",
        "This is on page 512, give or take.",
        "This is on page 136, five or take.",
        "The book itself has special structure in various places, trying to be isomorphic (or at least analogous) to fugues, self-referential constructs, etcetera. Some of them are quite obvious, but there are usually many levels. I’m confident that for each purposeful structure I’ve found there is deeper meaning that I’ve missed. For someone particularly intrigued by this, it could provide a lot of room for exploration (though, to be honest, I tend to be more interested in the content than the form… for now…).",
    ]

    return (
        <article>
            <h2>An Eternal Golden Braid</h2>
            <hr className={styles.top_seperator} />
            <section id="body-section">
                <p>This was certainly one of the more unique and enjoyable books I’ve read. Part philosophical, part technical, part aesthetic—neither fiction nor totally non-fiction—both surreal and mechanically elegant, <span style={{fontStyle: "italic"}}>G&ouml;del, Escher, Bach</span> probes into formal systems and their limitations, structure and chaos, self-reference and reproduction, and minds and machines (AI). Hofstadter manages to weave together all these related concepts into an inseparable braid, so to speak, in a way that manages to be both accessible and deep. With the exception of the SHRDLU (and related) section(s), <span style={{fontStyle: "italic"}}>G&ouml;del, Escher, Bach</span> has aged quite well, despite big changes in the AI landscape. I particularly enjoyed his fantastic parables involving Achilles and the Tortoise. Not only do they inject some levity when it’d be otherwise boggy, but they help illustrate his points in an aesthetically pleasing and potentially eye-opening way. The ant fugue was particularly compelling to me. Though in hindsight it may not appear to be anything out of the blue {footlink(1)}, imaging a colony as a “brain” was elucidating. Moreover, it did a good job of illustrating how intelligence can emerge from hierarchical composition of simple elements after multiple levels of abstraction {footlink(2)}.</p>
                <p>I’d recommend this book to anyone intellectually curious who is not already deeply involved in all these fields {footlink(3)}. I think that the implications of G&ouml;del’s theorem were more openly discussed back in Hofstadter’s time than in the present {footlink(4)} and while perhaps it’s hard to redirect something so specific (to mathematical logic) to another subject matter, it definitely has a lot of relevance to the field of minds and machines, representations, etcetera. When people are over-optimistic about our ability to “understand” the world (or model it) I usually think about basic counting arguments along the lines of “you cannot understand the world that well, because as your mind expands, due to the simulated world being less information dense than the real world, you cannot model anything close to its entirety,” however, G&ouml;del’s theorem is both broader and more limiting. Whereas my previous belief was that anything could be “formalized,” albeit in a useless way, the theorem suggests that any formalization is either not good enough to explain it totally, or irreparable (in other ways) if it is. This finding really does encourage us to value a more “Zen” approach to life.</p>
                <p>Like any good book, <span style={{fontStyle: "italic"}}>G&ouml;del, Escher, Bach</span> merits at least some re-reading. I don’t think I’ll be re-reading this entire chonker any time soon, but I would encourage anyone curious to review the construction {footlink(5)} used for G&ouml;del’s theorem (I know I probably will). Hofstadter also drops various interesting puzzles, some of which are actually quite hard. The hardest one {footlink(6)}, which I have yet to try in earnest (though it is my plan to do so) is to express “b is a power of ten” in his flavor of TNT {footlink(7)}. Expressing “b is a power of p” for any prime number p is easy (i.e. you have a finite sentence saying that there does not exist a number less than p and greater than two that divides b, and then say that there does not exist a number greater than p that divides b), however, checking that the power of two and of five have the same exponent is a formidable task to say the least. He also challenges us to create a self-replicating organism {footlink(8)} using his Typogenetic system (something I also did not attempt yet), and presents some cool recurrences {footlink(9)} (one, which is identical to the fibonacci numbers, but whose equality seems hard to prove). There are various others, including the MIU system early on, the Bongard problems (I guess), and expressing “b is a power of 2” though these are all easier {footlink(10)}.</p>
                <p>Anyways, friends have told me that this was a rewarding read for a while and they were certainly proven right. Some sections were a little bit of a let-down, but the others were truly worth it. I encourage anyone reading this to also set out to read <span style={{fontStyle: "italic"}}>G&ouml;del, Escher, Bach: An Eternal Golden Braid.</span></p>
            </section>
            <section id="footnotes-section">
                {footnotes.map((note, index) => footnote(index + 1, note))}
            </section>
            <section>
                <div className={styles.phone_home}>
                <a href="/">Click me to return home.</a>
                </div>
            </section>
        </article>
    )
}